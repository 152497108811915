// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Registramos a un usuario en Firebase, lo que conlleva la creación
 * de una cuenta en el sistema de autenticaciones de firebase, así
 * como la creación de compañía, establecimiento y usuario en BD (firestore).
 * Por último se configura también el paquete
 *
 * @param {object} data - Objeto con todos los datos necesarios para el registro
 * @return {object} - respuesta del servidor
 */
export async function registerUser(data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/auth/register',
    data: {
      data
    }
  })

  return result.data
}
