// Constants
import {
  ACTIVATION_TYPES,
  ACTIVATION_STATUS,
  ADDONS,
  COMPANY_TYPES,
  DEFAULT_LANGUAGE,
  DEFAULT_PASSWORD,
  PACKAGES,
  ROLES
} from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Store
import { mapGetters } from 'vuex'
// Services
import { registerUser } from '@/services/authentication'
import { getCompanyActivationById, updateCompanyActivationById } from '@/services/companyActivation'
import { getPlaceByCompanyActivationId } from '@/services/place'
// Utils
import { get, isNil } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'ActivationPlace',
  components: {
    CardContainer,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  data() {
    return {
      id: get(this.$route, 'params.id', null), // UID de la activación
      placeId: null, // UID del establecimiento creado (recuperado)
      processingRequest: false, // Loading
      // Elementos del interface
      proccessIconColor: 'green darken-2',
      proccessIconName: 'mdi-check-bold',
      proccessResult: true,
      proccessResultLabel:
        'El proceso finalizó correctamente. A continuación puede ir a gestionar las cartas del establecimiento.'
    }
  },
  computed: {
    ...mapGetters('company', ['companyDataConfig'])
  },
  async mounted() {
    // Obtenemos todos los datos necesarios de la APP
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtenemos todos los datos a mostrar en el componente
     */
    async getEveryNeededData() {
      try {
        this.processingRequest = true

        // Datos de la activación
        const companyActivationData = await this.getCompanyActivationById(this.id)

        if (isNil(companyActivationData)) {
          throw new Error('El identificador de la activación no es válido.')
        }

        // Datos del establecimiento
        const placeData = await this.getPlaceByCompanyActivationId(this.id)

        if (isNil(placeData)) {
          // Creamos establecimiento, compañía y usuario
          await this.createPlaceByCompanyActivation(companyActivationData)
          // Actualizamos el estado de la activación
          await updateCompanyActivationById({
            id: this.id,
            type: ACTIVATION_TYPES.assistant.value,
            status: ACTIVATION_STATUS.inProcess.value
          })
        } else {
          this.placeId = placeData.id
          this.proccessResultLabel =
            'El establecimiento ya existe, por lo que no es necesario volver a crearlo.'
        }
      } catch (error) {
        // Cambiamos elementos a mostrar
        this.proccessIconColor = 'orange darken-2'
        this.proccessIconName = 'mdi-alert-circle-outline'
        this.proccessResult = false
        this.proccessResultLabel = error.message
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos datos de la activación
     *
     * @param {string} id - UID de la activación
     * @returns {object | null} - datos de la activación
     */
    async getCompanyActivationById(id) {
      const companyActivation = await getCompanyActivationById(id)
      return companyActivation
    },
    /**
     * Obtenemos datos del establecimiento
     *
     * @param {string} id - UID de la activación
     * @returns {object | null} - datos de la activación*
     */
    async getPlaceByCompanyActivationId(id) {
      try {
        const place = await getPlaceByCompanyActivationId(id)
        return place
      } catch {
        return null
      }
    },
    /**
     * Creamos establecimiento asociado a la activación
     *
     * @param {object} data - Datos de la activación
     */
    async createPlaceByCompanyActivation(data) {
      const brandId = getEnvironmentVariable('VUE_APP_BRAND')
      // Creamos usuario, compañía y establecimiento
      const { placeId } = await registerUser({
        user: {
          brand: brandId,
          email: get(data, 'email', null),
          password: DEFAULT_PASSWORD,
          displayName: get(data, 'name', null),
          role: ROLES.horeca_admin.value
        },
        userClaims: {
          role: ROLES.horeca_admin.value
        },
        place: {
          brand: brandId,
          name: get(data, 'placeName', null),
          companyActivationId: get(data, 'id', null)
        },
        company: {
          brand: brandId,
          type: COMPANY_TYPES.horeca
        },
        addOns: [
          {
            packageId: PACKAGES.free.value,
            subscgitriptionData: {
              amount: 0,
              currency: 'EUR',
              priceId: 'custom',
              period: 36500
            }
          }
        ],
        addOnConfigs: [
          {
            target: 'place',
            id: ADDONS.place,
            configFields: {
              // Incluimos las configuraciones de la compañía
              additionalLanguages: this.companyDataConfig.additionalLanguages || [],
              currency: this.companyDataConfig.currency || null,
              defaultLanguage: this.companyDataConfig.defaultLanguage || DEFAULT_LANGUAGE,
              name: get(data, 'placeName', null),
              showCurrency: isNil(this.companyDataConfig.showCurrency)
                ? true
                : this.companyDataConfig.showCurrency
            }
          },
          {
            target: 'place',
            id: ADDONS.contact,
            configFields: {
              email: get(data, 'email', null),
              phone1: get(data, 'phone', null)
            }
          },
          {
            target: 'company',
            id: ADDONS.company,
            configFields: {
              // Configuraciones de comisión por defecto
              relationshipCommissions: {
                referrals: {
                  type: 'value',
                  value: 10
                }
              }
            }
          }
        ]
      })
      // Establecemos ID del establecimiento
      this.placeId = placeId
    }
  }
}
